import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";
import Login from "../views/examples-api/Login.vue";
import Customers from "@/views/examples-api/Customers.vue";
import Invoices from "@/views/examples-api/Invoices.vue";
import CreateInvoice from "@/views/examples-api/CreateInvoice.vue";
import CreateCustomer from "@/views/examples-api/CreateCustomer.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/login",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: '/customers',
    name: "Customers",
    component: Customers,
    meta: { requiresAuth: true },
  },
  {
    path: '/customers/create',
    name: "customer-create",
    component: CreateCustomer,
    meta: { requiresAuth: true },
  },
  {
    path: '/edit/customer/:customerId',
    name: "edit-customer",
    component: CreateCustomer,
    props: route => ({ customerId: parseInt(route.params.customerId) || null }),
    meta: { requiresAuth: true },
  },
  {
    path: '/invoices',
    name: "Invoices",
    component: Invoices,
    meta: { requiresAuth: true },
  },
  {
    path: '/invoices/update/:invoiceId?',
    name: 'invoices-edit',
    component: CreateInvoice,
    props: route => ({ invoiceId: parseInt(route.params.invoiceId) || null }),
    meta: { requiresAuth: true },
  },
  {
    path: '/invoices/create',
    name: "invoices-create",
    component: CreateInvoice,
    meta: { requiresAuth: true },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!JSON.parse(localStorage.getItem('user_free'));
  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ path: "/login" });
  } else {
    next();
  }
});

export default router;
